// Dependencies
import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from 'swiper';

import 'primeicons/primeicons.css';

// Styling
import './homepage.scss';
import 'swiper/css';
import 'swiper/css/navigation';

// Multi lang
import { withTranslation } from 'react-multi-lang';

// Redux
import { connect } from 'react-redux';
import { getOffersByPartner, searchOffer } from '../../redux/actions/offer';

// Images
import imagesection1 from '../../common/assets/images/eventbrite 8.png';
import univassouras from '../../common/assets/images/univassouras.jpg';
import pccp from '../../common/assets/images/pccp.webp';
import smartexai from '../../common/assets/images/smartexai.png';
import employercard from '../../common/assets/images/employer-card-img.png';
import partnercard from '../../common/assets/images/partner-card-img.png';
import training from '../../common/assets/images/training.png';
import wildcodeschool from '../../common/assets/images/wildcodeschool.png';
import bantumen from '../../common/assets/images/bantumen.png';
import digistep from '../../common/assets/images/digistep.png';
import edit from '../../common/assets/images/edit.png';
import smartexaic from '../../common/assets/images/smartexaic.png';
import speak from '../../common/assets/images/speak.png';
import union from '../../common/assets/images/union.png';
import searchicon from '../../common/assets/images/magnifying-glass-solid.svg';
import arrowicon from '../../common/assets/images/arrow-right-solid.svg';

SwiperCore.use([Pagination, EffectCoverflow]);

class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: ""
    };
  }

  componentDidMount() {

  }

  getOffersByPartner(partnerId, partner) {
    this.props.getOffersByPartner(partnerId, partner, this.goToOffers());
  }

  searchOffer(searchTerm) {
    this.props.searchOffer(searchTerm, this.goToOffers());
  }

  goToOffers() {
    setTimeout(() => {
      window.open("/offers", "_self");
    }, 500);

  }

  searchKeyDownHandler(evt) {
    if (evt.key === "Enter") {
      this.setState({ searchTerm: evt.target.value });
      this.props.searchOffer(evt.target.value, this.goToOffers());
    }
  }

  render() {

    return (
      <>

        {/* ----- Hero Section ---- */}

        <div className='hero-content-fluid pb-6'>
                <div className='justify-content-center flex'>
              <img className='w-12' src={imagesection1} alt={this.props.t("homepage.labels.job-offers")} />
            </div>
          </div>


        {/* ----- Carrousel Section ----- */}

        <div className='carrousel-fluid-container pt-5 '>

          {/* ----- Search Section ---- */}

          

          {/* ----- End Search Section  ---- */}

          <div className='container container-swiper grid py-5'>
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff"
              }}
              modules={[Navigation]}
              navigation={true}
              breakpoints={{
                640: {
                  slidesPerView: 1
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20
                }
              }}
              className="news-swiper"
            >
              <SwiperSlide className="news-swiper-slide">
                <p className='title-news'>Elisangela Souza, CEO & Founder</p>
                <p className='sub-title-news'>{this.props.t("homepage.labels.news-1")}</p>
              </SwiperSlide>
              {
                /*

              <SwiperSlide className="news-swiper-slide">
                <p className='title-news'>Elisangela Lima, CEO & Founder</p>
                <p className='sub-title-news'>{this.props.t("homepage.labels.news-1")}</p>
              </SwiperSlide>
              <SwiperSlide className="news-swiper-slide">
                <p className='title-news'>Elisangela Lima, CEO & Founder</p>
                <p className='sub-title-news'>{this.props.t("homepage.labels.news-1")}</p>
              </SwiperSlide>
              <SwiperSlide className="news-swiper-slide">
                <p className='title-news'>Elisangela Lima, CEO & Founder</p>
                <p className='sub-title-news'>{this.props.t("homepage.labels.news-1")}</p>
              </SwiperSlide>

                */
              }

            </Swiper>
          </div>
        </div>

        {/* ----- Job Section ----- */}

        <div className='job-fluid-container'>
          <div className='container grid py-8'>
            <div className='col-12 lg:col-8'>
              {/*<div className='job-rounded-bg px-8'>
                <div className='px-0 py-8 lg:px-8 lg:py-6'>
                  <p className='title'>
                    {this.props.t("homepage.labels.job-title")}
                  </p>
                  <p className='sub-title'>
                    {this.props.t("homepage.labels.job-subtitle")}
                  </p>
                  <p className='text'>
                    {this.props.t("homepage.labels.job-description")}
                  </p>
                  <button className='b-offers c-pointer' onClick={(evt) => { window.open("/offers", "_self"); }}>{this.props.t("homepage.labels.offers")}<img className='mgl-10' style={{ width: "3%", paddingTop: "3px" }} src={arrowicon} alt="Arrow"></img></button>
                </div>
              </div>*/}
            </div>
            <div className='col-12 lg:col-4 flex flex-row justify-content-center lg:justify-content-start align-content-end flex-wrap'>
              { /*
                <div className="card border-round shadow-2 ml-0 lg:-ml-8 mr-4 w-10rem h-10rem flex flex-column align-items-center">
                <img className='w-6 pb-2' src={devoteam} alt="Devoteam" />
                <div className='font-bold'>Devoteam</div>
                <div>1.943 {this.props.t("homepage.labels.needs")}</div>
              </div>
            */ }
              
            </div>
          </div>
        </div>

        {/* ----- Cards Section ----- */}

        <div className='cards-fluid-container pt-7'>
          <div className='container grid flex flex-column px-4 lg:px-0 lg:flex-row'>
            <div className='col-12 lg:col-6 lg:pr-5'>
              <div className='card employer-card-bg flex pb-0 pl-0 pt-6 h-25rem sm:h-20rem'>
                <div className='align-items-end flex'>
                  <img className='w-8rem lg:w-14rem' src={employercard} alt='Be an Employer' />
                </div>
                <div className='pl-3 lg:pl-6 lg:pr-5 flex flex-column'>
                  <p className='card-title'>
                    {this.props.t("homepage.labels.company")}
                  </p>
                  <p className='card-text'>
                    {this.props.t("homepage.labels.company-title")}
                  </p>
                  <p className='card-know-more c-pointer' onClick={(evt) => { window.open("/corporate", "_self"); }}>
                    {this.props.t("homepage.labels.more")} <img style={{ width: "3%", paddingTop: "3px" }} src={arrowicon} alt="Arrow"></img>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-12 lg:col-6 lg:col-6 lg:pl-5'>
              <div className='card partner-card-bg card-container flex pb-0 pl-0 pt-6 h-25rem sm:h-20rem'>
                <div className='align-items-end flex'>
                  <img className='w-10rem lg:w-18rem' src={partnercard} alt='Be an Partner' />
                </div>
                <div className='pl-3 lg:pl-6 lg:pr-5 flex flex-column'>
                  <p className='card-title'>
                    {this.props.t("homepage.labels.partner")}
                  </p>
                  <p className='card-text'>
                    {this.props.t("homepage.labels.partner-title")}
                  </p>
                  <p className='card-know-more c-pointer' onClick={(evt) => { window.open("/corporate", "_self"); }}>
                    {this.props.t("homepage.labels.more")} <img style={{ width: "3%", paddingTop: "3px" }} src={arrowicon} alt="Arrow"></img>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* ----- Training Section ----- */}

          <div className='container grid flex flex-row py-7'>
            <div className='col-12 lg:col-7'>
              <img className='w-full' src={training} alt='Training' />
            </div>
            <div className='col-12 lg:col-5 px-5 lg:px-8 pb-4 justify-content-end flex flex-column'>
              <p className='title'>
                {this.props.t("homepage.labels.training-title")}
              </p>
              <p className='sub-title'>
                {this.props.t("homepage.labels.training-subtitle")}
              </p>
              <p className='text'>
                {this.props.t("homepage.labels.training-description")}
              </p>
              <p className='training-application c-pointer' onClick={(evt) => { window.open("/offers", "_self"); }}>
                {this.props.t("homepage.labels.applies")} <img style={{ width: "3%", paddingTop: "3px" }} src={arrowicon} alt="Arrow"></img>
              </p>
            </div>
          </div>

          {/* ----- Partners Section ----- */}

          <div className='container grid flex flex-column py-7 align-items-center'>
            <p className='sub-title'>
              {this.props.t("homepage.labels.partners-title")}
            </p>
          </div>
          <div className='container container-swiper grid py-5'>
            <Swiper
              style={{
                "--swiper-navigation-color": "#000"
              }}
              modules={[Navigation]}
              navigation={true}
              breakpoints={{
                640: {
                  slidesPerView: 1
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20
                }
              }}
              className="partner-swiper"
            >
              <SwiperSlide className="support-swiper-slide flex justify-items-center">
                  <img className="w-3" style={{borderRadius : "15px"}} src={univassouras} alt='universidade de vassouras' />
              </SwiperSlide>
              <SwiperSlide className="support-swiper-slide ">
                  <img src={bantumen} alt='Bantumen' />
              </SwiperSlide>
              <SwiperSlide className="support-swiper-slide">
                
                  <img src={digistep} alt='DigiStep' />
              </SwiperSlide>
              <SwiperSlide className="support-swiper-slide">
                  <img src={pccp} alt='DigiStep' />
              </SwiperSlide>
           </Swiper>
          </div>


          {/* ----- Supports Section ----- */}

          {/*<div className='container grid flex flex-column py-7 align-items-center'>
            
          </div>
          <div className='container container-swiper grid py-5'>
            <Swiper
              style={{
                "--swiper-navigation-color": "#000"
              }}
              modules={[Navigation]}
              navigation={true}
              breakpoints={{
                640: {
                  slidesPerView: 1
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20
                }
              }}
              className="support-swiper"
            >
              <SwiperSlide className="support-swiper-slide flex justify-items-center">
                <img src={bantumen} alt='Bantumen' />
              </SwiperSlide>
              <SwiperSlide className="support-swiper-slide">
                <a href="https://digistep.io/">
                  <img src={digistep} alt='DigiStep' />
                </a>
              </SwiperSlide>
            </Swiper>
          </div>*/}
        </div>

        {/* ----- Application Submission Section ----- */}

        <div className='submission-fluid-container py-7'>
          <div className='container grid align-items-center flex flex-column'>
            <div className='col-6 text-center'>
              <p className='sub-title text-white'>{this.props.t("homepage.labels.application-submit")}</p>
            </div>
            <div>
              <button className='submission b-offers c-pointer' onClick={(evt) => { window.open("/offers", "_self"); }}>{this.props.t("homepage.labels.fill-form")}</button>
            </div>
          </div>
        </div>

      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOffersByPartner: (partnerId, partner, callback) => { dispatch(getOffersByPartner(partnerId, partner, callback)) },
    searchOffer: (term, callback) => { dispatch(searchOffer(term, callback)) }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(HomePage));