// Dependencies
import React, { Component } from 'react';
import { Toast } from 'primereact/toast';

// Styling
import './offers.scss';

// Multi lang
import { withTranslation } from 'react-multi-lang';

// Redux
import { connect } from 'react-redux';
import { getAllActiveOffers, getFeaturedOffers, getOfferDetail, searchOffer } from '../../redux/actions/offer';
import { getActiveTrainings, selectTraining } from '../../redux/actions/training';

// Images
import smartexai from '../../common/assets/images/smartexai.png';
import edit from '../../common/assets/images/edit.png';
import vancancy from '../../common/assets/images/vancancy.png';
import wildcodeschool from '../../common/assets/images/wildcodeschool.png';
import searchicon from '../../common/assets/images/magnifying-glass-solid.svg';
import arrowicon from '../../common/assets/images/arrow-right-solid.svg';

// Utils
import { getPrettyDate } from '../../common/utils/date-utils';
import CandidateService from '../../redux/services/candidate';

class OffersPage extends Component {

  constructor(props) {
    super(props);
    this._toast = React.createRef();
    this.state = {
        name: "",
        email: "",
        phone: "",
        birthPlace: "",
        communications: false,
        acceptConditions: false,
        searchTerm: "",
        // Validation variables
        validName: true,
        validEmail: true,
        validEmailFormat: true,
        validPhone: true,
        validBirthPlace: true,
        validConditions: true
    };
  }

  componentDidMount() {
    if(this.props.searchResultMode === false) {
      this.props.getFeaturedOffers();
    }

    if(this.props.trainings == null || this.props.trainings.length === 0) {
        this.props.getActiveTrainings();
    }
  }

  navigateToVacancyPage() {
    setTimeout(() => {
      window.open("/vacancy", "_self");
    }, 1000);
  }

  goToOffer(offerRef) {
      this.props.getOfferDetail(offerRef, this.navigateToVacancyPage());
  }

  goToTraining(training) {
    this.props.selectTraining(training);

     window.open("/signup", "_self");
  }

  updateCandidatureData(field, value) {
      this.setState({ [field]: value });
  }

  submitCandidature() {
    if(this.validateForm()) {
        let candidature = {
            candidate: {
              name: this.state.name,
              email: this.state.email,
              mobilePhone: this.state.phone,
              birthPlace: this.state.birthPlace,
              communications: this.state.communications
            },
            offerRef: ""
        };

        console.log(candidature);
        CandidateService.submitCandidature(candidature)
        .then((response) => {
            this._toast.current.show({ severity: 'success', detail: this.props.t("offers.messages.success"), life: 3000 });
        })
        .catch(error => {
            this._toast.current.show({ severity: 'error', detail: this.props.t("offers.messages.error"), life: 3000 });
        });
    }
  }

  validateForm() {
      // Email address custom validation
      let validEmail = (this.state.email !== "");
      let validEmailFormat = true;

      if(validEmail) {
          validEmailFormat = (this.state.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null);
      }

      this.setState({ 
          validName: (this.state.name !== ""),
          validEmail: validEmail,
          validEmailFormat: validEmailFormat,
          validPhone: (this.state.phone !== ""),
          validGender: (this.state.birthPlace !== ""),
          validConditions: (this.state.acceptConditions !== false)
      });

      return (
          (this.state.name !== "") &&
          (validEmail) &&
          (validEmailFormat) &&
          (this.state.phone !== "") &&
          (this.state.birthPlace !== null) &&
          (this.state.acceptConditions !== false)
      );
  }

  searchKeyDownHandler(evt) {
    if(evt.key === "Enter") {
      this.setState({ searchTerm: evt.target.value });
      this.props.searchOffer(evt.target.value);
    }
  }

  render() {
    return (

      <>

        {/* ----- Search Section ---- */}


        {/* ----- Job Vancancy Section ---- */}

        <div className='vancancy-bg'>
          {/*<div className='job-vacancy-full-container pt-8'>
            <div className='container grid'>
              <div className='col-12 lg:col-8'>
                <p className='title pb-0'>{this.props.t("offers.labels.job-offers")}</p>
                {
                    this.props.searchResultMode === true ?
                    <p className='sub-title'>{this.props.t("offers.labels.search-results") + ' "' + this.props.searchTerm + '"'}</p>
                    :
                    <p className='sub-title'>{this.props.t("offers.labels.recommended-offers")}</p>
                }
              </div>
              <div className='col-12 lg:col-4 pb-5 lg:pb-0'>
                  {
                      this.props.searchResultMode === true ?
                      <p className='c-pointer' onClick={(evt) => this.props.getFeaturedOffers()}>{this.props.t("offers.labels.view-featured")} 
                        <img style={{width: "3%", marginLeft: "3px", paddingTop: "3px" }} src={arrowicon} alt="Arrow"></img>
                      </p>
                      :
                      <p className='c-pointer' onClick={(evt) => this.props.getAllActiveOffers()}>{this.props.t("offers.labels.view-all")} <img style={{width: "3%", marginLeft: "3px", paddingTop: "3px" }} src={arrowicon} alt="Arrow"></img></p>
                  }
              </div>
            </div>
            <div className='container grid vacancy-cards flex flex-wrap pl-3 lg:pl-0'>
                {
                    this.props.searchResultMode === true ?
                          this.props.filteredOffers.map((offer) => { 
                            return <div key={offer.offerRef} className='card vacancy-card grid c-pointer' onClick={(evt) => this.goToOffer(offer.offerRef)}>
                                        <div key={offer.offerRef} className='col-4 flex flex-column align-items-center justify-content-center'>
                                          <img className='w-5 pb-2' src={smartexai} alt='WebSummit' />
                                          <div>{offer.partner}</div>
                                        </div>
                                        <div className='col-8 flex flex-column justify-content-center'>
                                          <p className='font-bold'>{offer.role}</p>
                                          <p>{offer.workplace}, Portugal</p> */}
                                          {/*<p className='text-200'>Há 14h</p>*/}
                                        {/* </div>
                                      </div>
                        })
                        :
                        this.props.featuredOffers.map((offer) => { 
                            return <div key={offer.offerRef} className='card vacancy-card grid c-pointer' onClick={(evt) => this.goToOffer(offer.offerRef)}>
                                        <div key={offer.offerRef} className='col-4 flex flex-column align-items-center justify-content-center'>
                                          <img className='w-5 pb-2' src={smartexai} alt='WebSummit' />
                                          <div>{offer.partner}</div>
                                        </div>
                                        <div className='col-8 flex flex-column justify-content-center'>
                                          <p className='font-bold'>{offer.role}</p>
                                          <p>{offer.workplace}, Portugal</p>
                                          {/*<p className='text-200'>Há 14h</p>*/}
                                        {/*</div>
                                      </div>
                        })
                }
            </div>
          </div>*/}

          {/* ----- Training Section ---- */}

          {/*<div className='training-full-container pt-8'>
            <div className='container grid'>
              <div className='col-12 lg:col-8'>
                <p className='title pb-0'>{this.props.t("offers.labels.training-title")}</p>
                <p className='sub-title'>{this.props.t("offers.labels.training-subtitle")}</p>
              </div>
            </div>
            <div className='container grid training-cards flex flex-wrap px-2 lg:pl-0'>
                {
                    this.props.trainings.map((training) => { 
                        return <div key={training.trainingId} className='card training-card c-pointer' onClick={(evt) => this.goToTraining(training)}>
                                  <img className='w-3 pb-2' src={training.partnerId === 9 ? edit : wildcodeschool} alt='Edit' />
                                  <p>{training.partner}</p>
                                  <p>{this.props.t("offers.labels.duration")}: {training.duration}</p>
                                  <p className='font-bold text-xl'>{training.title}</p>
                                  <p className='text-200'>{this.props.t("offers.labels.begin-date")}: {training.beginDate !== null ? getPrettyDate(training.beginDate) : training.beginDatePlaceholder}</p>
                                  <p className='text-200'>{this.props.t("offers.labels.end-date")}: {training.endDate !== null ? getPrettyDate(training.endDate) : training.endDatePlaceholder}</p>
                              </div>
                    })
                }
            </div>
          </div>

          {/* ----- Advantages Section ---- */}

        
        </div>

        {/* ----- Best Vancancy Section ---- */}

        <div className='best-vancancy-full-container relative z-2' style={{background:""}}>
          <div className='container grid flex flex-row py-6'>
            <div className='col-12 lg:col-4 lg:col-offset-1 text-center lg:text-start'>
              <p className='text-5xl text-white font-bold'>{this.props.t("offers.labels.vacancy-1")} <br />{this.props.t("offers.labels.vacancy-2")}  <br />{this.props.t("offers.labels.vacancy-3")}</p>
            </div>
          </div>
        </div>

        {/* ----- Vancancy Section ---- */}

        <div className='vancancy-match-full-container border-bottom-1 surface-border'>
          <div className='container grid'>
            <div className='col-12 lg:col-6 p-8 flex flex-column align-items-center lg:align-items-start text-center lg:text-start'>
              
              <p className='sub-title'>
                {this.props.t("offers.labels.apply-subtitle")}
              </p>
              <img className='w-12' src={vancancy} alt='Vancancy' />
            </div>
            <div className='vancancy-form col-12 lg:col-6 relative z-0 p-8'>
              <div>
                <p className='text'>{this.props.t("offers.labels.fill-form")}</p>
              </div>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("offers.form.name")}</label>
                <input
                  id='name'
                  type='text'
                  value={this.state.name}
                  placeholder={this.props.t("offers.form.name-placeholder")}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("name", evt.target.value)} 
                />
                { this.state.validName ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("offers.form.email")}</label>
                <input
                  id='name'
                  type='email'
                  value={this.state.email}
                  placeholder={this.props.t("offers.form.email-placeholder")}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("email", evt.target.value)} 
                />
                { this.state.validEmail ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
                { this.state.validEmailFormat ? null : <div className="invalid-message">{this.props.t("general.wrong-email-format")}</div> }
              </div>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("offers.form.mobile-phone")}</label>
                <input
                  id='name'
                  type='text'
                  value={this.state.phone}
                  placeholder={this.props.t("offers.form.mobile-phone-placeholder")}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("phone", evt.target.value)} 
                />
                { this.state.validPhone ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("offers.form.birth-country")}</label>
                <select
                  id='name'
                  type='text'
                  value={this.state.birthPlace}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("birthPlace", evt.target.value)} 
                >
                  <option value='' disabled selected>{this.props.t("offers.form.birth-country-select")}</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Afeganistão">Afeganistão</option>
                  <option value="África do Sul">África do Sul</option>
                  <option value="Albânia">Albânia</option>
                  <option value="Alemanha">Alemanha</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antigua e Deps">Antigua e Deps</option>
                  <option value="Arábia Saudita">Arábia Saudita</option>
                  <option value="Argélia">Argélia</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Arménia">Arménia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Austrália">Austrália</option>
                  <option value="Áustria">Áustria</option>
                  <option value="Azerbaijão">Azerbaijão</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Bélgica">Bélgica</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermudas">Bermudas</option>
                  <option value="Bielorússia">Bielorússia</option>
                  <option value="Bolívia">Bolívia</option>
                  <option value="Bósnia Herzegovina">Bósnia Herzegovina</option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brasil">Brasil</option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgária">Bulgária</option>
                  <option value="Burkina">Burkina</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Butão">Butão</option>
                  <option value="Cabo Verde">Cabo Verde</option>
                  <option value="Camarões">Camarões</option>
                  <option value="Camboja">Camboja</option>
                  <option value="Canadá">Canadá</option>
                  <option value="Catar">Catar</option>
                  <option value="Cazaquistão">Cazaquistão</option>
                  <option value="Chade">Chade</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Chipre">Chipre</option>
                  <option value="Cidade do Vaticano">Cidade do Vaticano</option>
                  <option value="Colômbia">Colômbia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Coreia do Norte">Coreia do Norte</option>
                  <option value="Coreia do Sul">Coreia do Sul</option>
                  <option value="Costa do Marfim">Costa do Marfim</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Croácia">Croácia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Curaçao">Curaçao</option>
                  <option value="Dinamarca">Dinamarca</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Egito">Egito</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Emirados Árabes Unidos">Emirados Árabes Unidos</option>
                  <option value="Equador">Equador</option>
                  <option value="Eritreia">Eritreia</option>
                  <option value="Eslováquia">Eslováquia</option>
                  <option value="Eslovénia">Eslovénia</option>
                  <option value="Espanha">Espanha</option>
                  <option value="Estados Unidos">Estados Unidos</option>
                  <option value="Estónia">Estónia</option>
                  <option value="Etiópia">Etiópia</option>
                  <option value="Federação Russa">Federação Russa</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Filipinas">Filipinas</option>
                  <option value="Finlândia">Finlândia</option>
                  <option value="França">França</option>
                  <option value="Gabão">Gabão</option>
                  <option value="Gâmbia">Gâmbia</option>
                  <option value="Gana">Gana</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Grécia">Grécia</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Gronelândia">Gronelândia</option>
                  <option value="Guadalupe">Guadalupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guiana">Guiana</option>
                  <option value="Guiana Francesa">Guiana Francesa</option>
                  <option value="Guiné">Guiné</option>
                  <option value="Guiné Equatorial">Guiné Equatorial</option>
                  <option value="Guiné-bissau">Guiné-bissau</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Holanda">Holanda</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungria">Hungria</option>
                  <option value="Iémen">Iémen</option>
                  <option value="Ilhas Caimão">Ilhas Caimão</option>
                  <option value="Ilhas Cook">Ilhas Cook</option>
                  <option value="Ilhas Faroé">Ilhas Faroé</option>
                  <option value="Ilhas Marshall">Ilhas Marshall</option>
                  <option value="Ilhas Salomão">Ilhas Salomão</option>
                  <option value="Índia">Índia</option>
                  <option value="Indonésia">Indonésia</option>
                  <option value="Irão">Irão</option>
                  <option value="Iraque">Iraque</option>
                  <option value="Irlanda">Irlanda</option>
                  <option value="Islândia">Islândia</option>
                  <option value="Israel">Israel</option>
                  <option value="Itália">Itália</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japão">Japão</option>
                  <option value="Jordânia">Jordânia</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Laos">Laos</option>
                  <option value="Lesoto">Lesoto</option>
                  <option value="Letónia">Letónia</option>
                  <option value="Líbano">Líbano</option>
                  <option value="Libéria">Libéria</option>
                  <option value="Líbia">Líbia</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lituânia">Lituânia</option>
                  <option value="Luxemburgo">Luxemburgo</option>
                  <option value="Macau">Macau</option>
                  <option value="Macedonia">Macedonia</option>
                  <option value="Madagáscar">Madagáscar</option>
                  <option value="Malásia">Malásia</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Maldivas">Maldivas</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marrocos">Marrocos</option>
                  <option value="Martinica">Martinica</option>
                  <option value="Maurício">Maurício</option>
                  <option value="Mauritânia">Mauritânia</option>
                  <option value="México">México</option>
                  <option value="Mianmar">Mianmar</option>
                  <option value="Micronésia">Micronésia</option>
                  <option value="Moçambique">Moçambique</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Mongólia">Mongólia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Nicarágua">Nicarágua</option>
                  <option value="Níger">Níger</option>
                  <option value="Nigéria">Nigéria</option>
                  <option value="Noruega">Noruega</option>
                  <option value="Nova Zelândia">Nova Zelândia</option>
                  <option value="Omã">Omã</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestina">Palestina</option>
                  <option value="Panamá">Panamá</option>
                  <option value="Papua Nova Guiné">Papua Nova Guiné</option>
                  <option value="Paquistão">Paquistão</option>
                  <option value="Paraguai">Paraguai</option>
                  <option value="Peru">Peru</option>
                  <option value="Polinésia Francesa">Polinésia Francesa</option>
                  <option value="Polônia">Polônia</option>
                  <option value="Porto Rico">Porto Rico</option>
                  <option value="Quénia">Quénia</option>
                  <option value="Quirguistão">Quirguistão</option>
                  <option value="Reino Unido">Reino Unido</option>
                  <option value="Rep. Centro-Africano">Rep. Centro-Africano</option>
                  <option value="Rep. Democrática Congo">Rep. Democrática Congo</option>
                  <option value="República Checa">República Checa</option>
                  <option value="República Dominicana">República Dominicana</option>
                  <option value="Reunião">Reunião</option>
                  <option value="Romênia">Romênia</option>
                  <option value="Ruanda">Ruanda</option>
                  <option value="Samoa">Samoa</option>
                  <option value="Samoa Americana">Samoa Americana</option>
                  <option value="Santa Lúcia">Santa Lúcia</option>
                  <option value="São Marino">São Marino</option>
                  <option value="São Tomé e Príncipe">São Tomé e Príncipe</option>
                  <option value="São Vicente e Granadinas">São Vicente e Granadinas</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serra Leoa">Serra Leoa</option>
                  <option value="Sérvia">Sérvia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Singapura">Singapura</option>
                  <option value="Síria">Síria</option>
                  <option value="Somália">Somália</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Suazilândia">Suazilândia</option>
                  <option value="Sudão">Sudão</option>
                  <option value="Suécia">Suécia</option>
                  <option value="Suíça">Suíça</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Tahiti">Tahiti</option>
                  <option value="Tailândia">Tailândia</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajiquistão">Tajiquistão</option>
                  <option value="Tanzânia">Tanzânia</option>
                  <option value="Timor Leste">Timor Leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Trinidad e Tobago">Trinidad e Tobago</option>
                  <option value="Tunísia">Tunísia</option>
                  <option value="Turcomenistão">Turcomenistão</option>
                  <option value="Turquia">Turquia</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Ucrânia">Ucrânia</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Uruguai">Uruguai</option>
                  <option value="Uzbequistão">Uzbequistão</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietname">Vietname</option>
                  <option value="Zaire">Zaire</option>
                  <option value="Zâmbia">Zâmbia</option>
<option value="Zimbábue">Zimbábue</option>
                </select>
                { this.state.validBirthPlace ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
              <div className='field-checkbox pt-3'>
                <input type='checkbox' id='privacy-policy' defaultChecked={this.state.acceptConditions} onChange={(evt) => this.updateCandidatureData("acceptConditions", evt.target.checked)} />
                <label>
                  {this.props.t("offers.form.accept-cookies")}
                  <a style={{ color: 'inherit', textDecoration: 'underline' }} href={"/privacy-policy"} target="_self">{this.props.t("offers.form.privacy-policy")}</a>
                </label>
              </div>
              <div className='field-checkbox pt-3'>
                <input type='checkbox' id='newsletter' defaultChecked={this.state.communications} onChange={(evt) => this.updateCandidatureData("communications", evt.target.checked)}/>
                <label>{this.props.t("offers.form.accept-comms")}</label>
              </div>
              <div className='flex justify-content-end'>
                <input type='submit' className='b-form c-pointer submit-button pt-3' value={this.props.t("offers.form.submit")} onClick={(evt) => this.submitCandidature()} />
              </div>
            </div>
          </div>
          <Toast position='bottom-left' ref={this._toast} />
        </div>

      </>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    featuredOffers: state.offer.featuredOffers,
    filteredOffers: state.offer.filteredOffers,
    searchResultMode: state.offer.searchResultMode,
    searchTerm: state.offer.searchTerm,
    trainings: state.training.trainings
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveTrainings: () => { dispatch(getActiveTrainings()) },
    selectTraining: (training) => { dispatch(selectTraining(training)) },
    getFeaturedOffers: () => { dispatch(getFeaturedOffers()) },
    getOfferDetail: (offerRef, callback) => { dispatch(getOfferDetail(offerRef, callback)) },
    getAllActiveOffers: () => { dispatch(getAllActiveOffers()) },
    searchOffer: (term) => { dispatch(searchOffer(term)) }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(OffersPage));