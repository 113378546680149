// Dependencies
import React, { Component } from 'react';

// Multi lang
import { withTranslation } from 'react-multi-lang';

// Images
import diversidade from '../../common/assets/images/diversidade.jpg';
import diagnostico from '../../common/assets/images/diagnostico.png';
import estrategia from '../../common/assets/images/estrategia.png';
import gerenciamento from '../../common/assets/images/plano-de-negocios.png';
import implementacao from '../../common/assets/images/implementacao.png';


// Styling
import './diversidade.scss';
// Redux
import { connect } from 'react-redux';

class Diversidade extends Component {
    render() {
        return (
            <>
                <div className='layout-main-container'>
                    <div className='layout-main'>
                        <div className='diversidade-title-full-content'>
                            <div className='diversidade-title-container container'>
                                <div className='text-left py-8 flex flex-column lg:flex-row lg:w-full justify-content-center align-items-center'>
                                    <div className='title p-4 md:w-9 flex-column lg:mr-3'>
                                        <p className='text-white text-5x1 font-bold line-height-3'>{this.props.t("diversidade.labels.diversidade-title")}</p>
                                        <p className='text-white'>{this.props.t("diversidade.labels.diversidade-text")}</p>
                                    </div>
                                    <div className='img-title'>
                                        <img 
                                            className='diversidade-img h-15rem md:h-20rem lg:h-30rem' 
                                            src={diversidade} 
                                            alt="Descrição da imagem"
                                            style={{borderRadius: "2rem 6rem 2rem 6rem" }} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='about-full-content'>
                            <div className='text-center'>
                                <h1 className='about-text'style={{fontSize:"36px", color:"#9E5FA2"}}>{this.props.t("diversidade.labels.about")}</h1>
                            </div>
                            <div className='containe flex flex-column align-items-center pt-20 px-5 lg:px-0text-center'>
                                <div className="event-card card flex flex-column align-items-center px-4">
                                    <img className='pb-6' src={diagnostico}  alt='WebSummit' />
                                    <p className='title text-center' style={{fontSize:"20px", color:"#9E5FA2",}}>{this.props.t("diversidade.labels.about-title-1")}</p>
                                    <p className='text-center' style={{fontSize:"15px", color:"#9E5FA2"}}>{this.props.t("diversidade.labels.about-info-1")}</p>
                                </div>
                                <div className="event-card card flex flex-column align-items-center px-4">
                                    <img className='pb-6' src={estrategia} alt='WebSummit' />
                                    <p className='title text-center'style={{fontSize:"20px", color:"#9E5FA2"}}>{this.props.t("diversidade.labels.about-title-2")}</p>
                                    <p className='text-center'style={{fontSize:"15px", color:"#9E5FA2"}}>{this.props.t("diversidade.labels.about-info-2")}</p>
                                    
                                </div>
                                <div className="event-card card flex flex-column align-items-center px-4">
                                    <img className='pb-6' src={gerenciamento}  alt='WebSummit' />
                                    <p className='title text-center'style={{fontSize:"20px", color:"#9E5FA2"}}>{this.props.t("diversidade.labels.about-title-4")}</p>
                                    <p className='text-center'style={{fontSize:"15px", color:"#9E5FA2"}}>{this.props.t("diversidade.labels.about-info-4")}</p>
                                    
                                </div>
                                <div className="event-card card flex flex-column align-items-center px-4">
                                    <img className='pb-6' src={implementacao}  alt='WebSummit' />
                                    <p className='title text-center'style={{fontSize:"20px", color:"#9E5FA2"}}>{this.props.t("diversidade.labels.about-title-3")}</p>
                                    <p className='text-center'style={{fontSize:"15px", color:"#9E5FA2"}}>{this.props.t("diversidade.labels.about-info-3")}</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(Diversidade));