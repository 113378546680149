// Dependencies
import React, { Component } from 'react';

// Multi lang
import { withTranslation } from 'react-multi-lang';

// Images

import recrutamento from '../../common/assets/images/recrutamento.jpeg';
import triagem from '../../common/assets/images/triagem.png';
import networking from '../../common/assets/images/networking.png';
import acompanhar from '../../common/assets/images/acompanhar.png';
import sensibilidade from '../../common/assets/images/sensibilidade.png';


// Styling
import './recrutamento.scss';
// Redux
import { connect } from 'react-redux';

class Recrutamento extends Component {
    render() {
        return (
            <>
                <div className='layout-main-container'>
                    <div className='layout-main'>
                        <div className='recrutamento-title-full-content'>
                            <div className='recrutamento-title-container container'>
                                <div className='text-left py-8 flex flex-column lg:flex-row lg:w-full justify-content-center align-items-center'>
                                    <div className='title p-4 md:w-9 flex-column lg:mr-3'>
                                        <p className='text-white text-5x1 font-bold line-height-3'>{this.props.t("recrutamento.labels.recrutamento-title")}</p>
                                        <p className='text-white'>{this.props.t("recrutamento.labels.recrutamento-text")}</p>
                                    </div>
                                    <div className='img-title'>
                                        <img 
                                            className='recrutamento-img h-15rem md:h-20rem lg:h-30rem' 
                                            src={recrutamento} 
                                            alt="Descrição da imagem"
                                            style={{borderRadius: "2rem 6rem 2rem 6rem" }} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='about-full-content'>
                            <div className='text-center'>
                                <h1 className='about-text'style={{fontSize:"36px", color:"#E65531"}}>{this.props.t("recrutamento.labels.about")}</h1>
                            </div>
                            <div className='containe flex flex-column align-items-center pt-20 px-5 lg:px-0text-center'>
                                <div className="event-card card flex flex-column align-items-center px-4">
                                    <img className='pb-6' src={triagem}  alt='WebSummit' />
                                    <p className='title text-center' style={{fontSize:"20px", color:"#E65531",}}>{this.props.t("recrutamento.labels.about-title-1")}</p>
                                    <p className='text-center' style={{fontSize:"15px", color:"#E65531"}}>{this.props.t("recrutamento.labels.about-info-1")}</p>
                                </div>
                                <div className="event-card card flex flex-column align-items-center px-4">
                                    <img className='pb-6' src={networking} alt='WebSummit' />
                                    <p className='title text-center'style={{fontSize:"20px", color:"#E65531"}}>{this.props.t("recrutamento.labels.about-title-2")}</p>
                                    <p className='text-center'style={{fontSize:"15px", color:"#E65531"}}>{this.props.t("recrutamento.labels.about-info-2")}</p>
                                    
                                </div>
                                <div className="event-card card flex flex-column align-items-center px-4">
                                    <img className='pb-6' src={sensibilidade}  alt='WebSummit' />
                                    <p className='title text-center'style={{fontSize:"20px", color:"#E65531"}}>{this.props.t("recrutamento.labels.about-title-4")}</p>
                                    <p className='text=center'style={{fontSize:"15px", color:"#E65531"}}>{this.props.t("recrutamento.labels.about-info-4")}</p>
                                    
                                </div>
                                <div className="event-card card flex flex-column align-items-center px-4">
                                    <img className='pb-6' src={acompanhar}  alt='WebSummit' />
                                    <p className='title text-center'style={{fontSize:"20px", color:"#E65531"}}>{this.props.t("recrutamento.labels.about-title-3")}</p>
                                    <p className='text-center'style={{fontSize:"15px", color:"#E65531"}}>{this.props.t("recrutamento.labels.about-info-3")}</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(Recrutamento));