// Dependencies
import React, { Component } from 'react';
import { Menubar } from 'primereact/menubar';
import logo from '../../common/assets/images/logo1.png';

// Styling
import './header.scss';

// Redux
import { connect } from 'react-redux';

// Multi lang
import { setLanguage, withTranslation } from 'react-multi-lang';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }

  render() {

    const items = [
      {
        label: this.props.t("services.title"),
        command: () => {window.open("/services", "_self");
        },
      },
      
      {
        label: this.props.t("offers.title"),
        command: () => { window.open("/offers", "_self"); }
      },
      {
        style: { whiteSpace: "nowrap",  },
        label: this.props.t("about-us.title"),
        command: () => { window.open("/about-us", "_self"); }
      },
      {
        label: this.props.t("corporate.title"),
        command: () => { window.open("/corporate", "_self"); }
      },
      {
        label: this.props.t("events.title"),
        command: () => { window.open("/events", "_self"); }
      }
    ];

    return (
      <div className="header-content-fluid">
        <div className='container grid navbar py-3'>
          <div className=' col-4 lg:col-2 pl-3 lg:pl-0'>
            <a style={{ color: 'white' }} href={"/"} target="_self">
              <img className='h-7rem' src={logo} alt='logo' />
            </a>
          </div>
          <div className='col-4 lg:col-8 align-self-center' style={{ color :"white"}}>
            <Menubar className='border-noround border-none justify-content-center lg:justify-content-end p-0 m-0' model={items} style={{ fontSize:"16px", fontWeight:"bold" }}/>
          </div>
          {/* (!isMobile) ?
            <div className='lg:col-2 text-right align-self-center'>
              <InputText placeholder={this.props.t("header.labels.search")} />
            </div> : null */
          }
          { /* <div className='col-4 lg:col-2 align-self-center'>Login</div> */}
          <div className='col-4 lg:col-2 flex flex-row align-items-center justify-content-end'>
            <button className='b-offers c-pointer mr-2 b-lang font-medium' onClick={(evt) => setLanguage('pt')}>PT</button>
            <button className='b-offers c-pointer b-lang font-medium' onClick={(evt) => setLanguage('en')}>EN</button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(Header));