import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter  } from 'react-router-dom';

// Redux
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './redux/reducers/root-reducer';
import { loadState, saveState} from './common/state-loader';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

// Styling 
import './index.css';

// Fonts
import WebFont from 'webfontloader';

// Multi Lang
import { setTranslations, setDefaultLanguage } from 'react-multi-lang';
import pt from './lang/pt.json';
import en from './lang/en.json';

setTranslations({pt, en});
setDefaultLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE);

WebFont.load({
  google: {
    //families: ['Titillium Web:300,400,700', 'sans-serif']
    //families: ['Montserrat:300,400,700', 'sans-serif']
    families: ['Poppins: 200,400,500,700', 'Nokora: 700,900','sans-serif']

  }
});

const persistedState = loadState();
const store = createStore(rootReducer, persistedState, applyMiddleware(thunk));

store.subscribe(() => {
    saveState(store.getState());
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter >
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
