// Dependencies
import React, { Component } from 'react';

// Multi lang
import { withTranslation } from 'react-multi-lang';

// Images
import servicesimg from '../../common/assets/images/job.png';
import Handshake from '../../common/assets/images/Handshake.png';
import thinking from '../../common/assets/images/thinking.png';
import research from '../../common/assets/images/research.png';


// Styling
import './services.scss';
// Redux
import { connect } from 'react-redux';

class Services extends Component {
    render() {
        return (
            <>
                <div className='layout-main-container'>
                    <div className='layout-main'>
                        <div className='services-title-full-content'>
                            <div className='services-title-container container'>
                                <div className='text-left py-8 flex flex-column lg:flex-row lg:w-full justify-content-center align-items-center'>
                                    <div className='title p-4 md:w-9 flex-column lg:mr-3'>
                                        <p className='text-white text-5x1 font-bold line-height-3'>{this.props.t("services.labels.services-title")}</p>
                                        <p className='text-white'>{this.props.t("services.labels.services-text")}</p>
                                    </div>
                                    <div className='img-title'>
                                        <img 
                                            className='services-img h-15rem md:h-20rem lg:h-30rem' 
                                            src={servicesimg} 
                                            alt="Descrição da imagem"
                                            style={{borderRadius: "2rem 6rem 2rem 6rem" }} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ----- Partners and Hiring Section ---- */}

                        <div className='partners-hiring-full-content'>
                            <div className='container grid py-8'>
                                <div className='col-12 lg:col-6 text pb-8 px-5'>
                                    <p className='font-bold pb-3'>{this.props.t("corporate.labels.diversity-title")}</p>
                                    <li>{this.props.t("corporate.labels.diversity-description-1")}</li>
                                    <li>{this.props.t("corporate.labels.diversity-description-2")}</li>
                                    <li>{this.props.t("corporate.labels.diversity-description-3")}</li>
                                    <li>{this.props.t("corporate.labels.diversity-description-4")}</li>
                                </div>
                                <div className='col-12 lg:col-6 text px-5'>
                                    <p className='font-bold pb-3'>{this.props.t("corporate.labels.recruitment-title")}</p>
                                    <p>{this.props.t("corporate.labels.recruitment-description")}</p>
                                </div>
                                <div className='col-12 lg:col-6 text pb-8 px-5'>
                                    <p className='font-bold pb-3'>{this.props.t("corporate.labels.team-title")}</p>
                                    <p>{this.props.t("corporate.labels.team-description-1")}</p>
                                    <li>{this.props.t("corporate.labels.team-description-2")}</li>
                                    <li>{this.props.t("corporate.labels.team-description-3")}</li>
                                    <li>{this.props.t("corporate.labels.team-description-4")}</li>
                                    <li>{this.props.t("corporate.labels.team-description-5")}</li>
                                    <li>{this.props.t("corporate.labels.team-description-6")}</li>
                                </div>
                                <div className='col-12 lg:col-6 text px-5'>
                                    <p className='font-bold pb-3'>{this.props.t("corporate.labels.leadership-title")}</p>
                                    <p>{this.props.t("corporate.labels.leadership-description-1")}</p>
                                    <li>{this.props.t("corporate.labels.leadership-description-2")}</li>
                                    <li>{this.props.t("corporate.labels.leadership-description-3")}</li>
                                    <li>{this.props.t("corporate.labels.leadership-description-4")}</li>
                                    <li>{this.props.t("corporate.labels.leadership-description-5")}</li>
                                    <li>{this.props.t("corporate.labels.leadership-description-6")}</li>
                                    <li>{this.props.t("corporate.labels.leadership-description-7")}</li>
                                </div>
                                <div className='col-12 lg:col-6 text px-5'>
                                    <p className='font-bold pb-3'>{this.props.t("corporate.labels.upskill-title")}</p>
                                    <p>{this.props.t("corporate.labels.upskill-description")}</p>
                                    </div>
                                    <div className='col-12 lg:col-6 text px-5'>
                                    <p className='font-bold pb-3'>{this.props.t("corporate.labels.offers-title")}</p>
                                    <p>{this.props.t("corporate.labels.offers-description")}</p>
                                    </div>
                                    <div className='col-12 lg:col-6 text px-5'>
                                    <p className='font-bold pb-3'>{this.props.t("corporate.labels.career-title")}</p>
                                    <p>{this.props.t("corporate.labels.career-description")}</p>
                                </div>
                            </div>
                        </div>
                        <div className='about-full-content'>
                            <div className='text-center'>
                                <h1 className='about-text'style={{fontSize:"36px"}}>{this.props.t("services.labels.about")}</h1>
                            </div>
                            <div className='container grid pt-20 px-5 lg:px-0 justify-content-around text-center'>
                                <div className="event-card card flex flex-column justify-content-center flex-wrap px-4">
                                    <img className='icon pb-6' src={research}  alt='WebSummit' />
                                    <p className='title' style={{color:"#E65531",}}>{this.props.t("services.labels.about-title-1")}</p>
                                    <p className='text' style={{color:"#E65531"}}>{this.props.t("services.labels.about-info-3")}</p>
                                    <button
                                        className="b-offers c-pointer align-self-end border-round-3xl w-12 m-auto py-3"
                                        onClick={(evt) => {
                                            window.open('/recrutamento', "_blank");
                                        }}
                                        style={{background:"#E65531"}}
                                    >Saiba Mais</button>
                                </div>
                                <div className="event-card card flex flex-column justify-content-center flex-wrap px-4">
                                    <img className='icon pb-6' src={Handshake} alt='WebSummit' />
                                    <p className='title'style={{color:"#9E5FA2"}}>{this.props.t("services.labels.about-title-2")}</p>
                                    <p className='text'style={{color:"#9E5FA2"}}>{this.props.t("services.labels.about-info-2")}</p>
                                    <button
                                        className="b-offers c-pointer align-self-end border-round-3xl w-12 m-auto py-3"
                                        onClick={(evt) => {
                                            window.open('/diversidade', "_blank");
                                        }}
                                        style={{width: "10px", background:"#9E5FA2"}}
                                    > Saiba Mais</button>
                                </div>
                                <div className="event-card card flex flex-column justify-content-center flex-wrap px-4">
                                    <img className='icon pb-6' src={thinking}  alt='WebSummit' />
                                    <p className='title'style={{color:"#B3CD47"}}>{this.props.t("services.labels.about-title-3")}</p>
                                    <p className='text'style={{color:"#B3CD47"}}>{this.props.t("services.labels.about-info-1")}</p>
                                    <button
                                        className="b-offers c-pointer align-self-end border-round-3xl w-12 m-auto py-3"
                                        onClick={(evt) => {
                                            window.open('/censo', "_blank");
                                        }}
                                        style={{background:"#B3CD47"}}
                                    >Saiba Mais</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(Services));