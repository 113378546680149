import { GET_ACTIVE_OFFERS_SUCCESS, GET_FEATURED_OFFERS_SUCCESS, GET_OFFERS_BY_PARTNER_SUCCESS, GET_OFFER_DETAIL_SUCCESS, SEARCH_OFFERS_SUCCESS } from "../action-types/offer";

const initialState = {
    offer: null,
    featuredOffers: [],
    filteredOffers: [],
    searchResultMode: false,
    searchTerm: ""
};

const offerReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_FEATURED_OFFERS_SUCCESS:
            return {
                ...state,
                featuredOffers: action.offers.offers,
                searchResultMode: false,
                searchTerm: ""
            };
        case GET_OFFERS_BY_PARTNER_SUCCESS:
            console.log(action.searchTerm);
            return {
                ...state,
                filteredOffers: action.offers.offers,
                searchTerm: action.searchTerm,
                searchResultMode: true
            };
        case GET_ACTIVE_OFFERS_SUCCESS:
            return {
                ...state,
                filteredOffers: action.offers.offers,
                searchTerm: "Todas as vagas",
                searchResultMode: true
            };
        case SEARCH_OFFERS_SUCCESS:
            return {
                ...state,
                filteredOffers: action.offers.offers,
                searchTerm: action.searchTerm,
                searchResultMode: true
            };
        case GET_OFFER_DETAIL_SUCCESS:
            return {
                ...state,
                offer: action.offer.offer
            };
        default:
            return state;
    }
}

export default offerReducer;