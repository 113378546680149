import { Routes, Route } from 'react-router-dom';
import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';

import './App.scss';
import './styles/layout.scss';
import './styles/themes/primeflex.min.css';
import './styles/themes/theme.css';

import Header from './components/header/header';
import Footer from './components/footer/footer';
import { AboutUsPage, HomePage, CorporatePage, EventsPages, OffersPage, PrivacyPolicyPage, VacancyPage, SignUp, Recrutamento, Diversidade, Censo, Services } from './pages';


function App() {
  PrimeReact.ripple = true;
  
  
  return (
    <div className="App">
        <Header/>

        <div className="layout-main-container">
          <div className="layout-main">
              <Routes>
                <Route path={"/"} exact element={<HomePage />} />
                <Route path={"/about-us"} exact element={<AboutUsPage />} />
                <Route path={"/corporate"} exact element={<CorporatePage />} />
                <Route path={"/events"} exact element={<EventsPages />} />
                <Route path={"/offers"} exact element={<OffersPage />} />
                <Route path={"/privacy-policy"} exact element={<PrivacyPolicyPage />} />
                <Route path={"/vacancy"} exact element={<VacancyPage />} />
                <Route path={"/signup"} exact element={<SignUp />} />
                <Route path={"/recrutamento"} exact element={<Recrutamento />} />
                <Route path={"/censo"} exact element={<Censo />} />
                <Route path={"/diversidade"} exact element={<Diversidade />} />
                <Route path={"/services"} exact element={<Services />} />
              </Routes>
          </div>
        </div>  

        <Footer/>
    </div>
  );
}

export default App;
