import httpRequest from '../../common/lib/http-request';

// *************************
// TRAINING SERVICES
// *************************

function getActiveTrainings() {
    return httpRequest({
        url: '/Training',
        method: 'GET'
    });
}

function submitCandidature(candidatureData) {
    return httpRequest({
        url: '/Training/candidature',
        method: 'POST',
        data: candidatureData
    });
}

const TrainingService = { 
    getActiveTrainings,
    submitCandidature
}

export default TrainingService;