// Dependencies
import React, { Component } from 'react';
import { Toast } from 'primereact/toast';

// Styling
import './signup.scss';

// Multi lang
import { withTranslation } from 'react-multi-lang';

// Redux
import { connect } from 'react-redux';
import TrainingService from '../../redux/services/training';

// Images
import edit from '../../common/assets/images/edit.png';
import wildcodeschool from '../../common/assets/images/wildcodeschool.png';

// Utils
import { getPrettyDate } from '../../common/utils/date-utils';

class SignUp extends Component {

  constructor(props) {
    super(props);
    this._toast = React.createRef();
    this.state = {
        name: "",
        email: "",
        phone: "",
        birthPlace: "",
        birthDate: null,
        gender: "",
        ethnic: "",
        portugueseResidenceTime: "",
        schoolLevel: "",
        profession: "",
        jobSituation: "",
        englishLevel: "",
        idesocialhub: "",
        itExperience: false,
        acceptConditions: false,
        // Validation variables
        validName: true,
        validEmail: true,
        validEmailFormat: true,
        validPhone: true,
        validGender: true,
        validBirthDate: true,
        validIdesocialhub: true,
        validConditions: true
    };
  }

  componentDidMount() {

  }

  updateCandidatureData(field, value) {
      this.setState({ [field]: value });
  }

  submitCandidature() {
    if(this.validateForm()) {
        let candidature = {
            trainingCourseId: this.props.selectedTraining.trainingId,
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            birthPlace: this.state.birthPlace,
            birthDate: this.state.birthDate,
            gender: this.state.gender,
            ethnic: this.state.ethnic,
            portugueseResidenceTime: this.state.portugueseResidenceTime,
            schoolLevel: this.state.schoolLevel,
            profession: this.state.profession,
            jobSituation: this.state.jobSituation,
            englishLevel: this.state.englishLevel,
            idesocialhub: this.state.idesocialhub,
            itExperience: (this.state.itExperience === 'true')
        };

      TrainingService.submitCandidature(candidature)
      .then((response) => {
          this._toast.current.show({ severity: 'success', detail: this.props.t("candidature-signup.messages.success"), life: 3000 });
      })
      .catch(error => {
          this._toast.current.show({ severity: 'error', detail: this.props.t("candidature-signup.messages.error"), life: 3000 });
      });
    }
  }

  validateForm() {
      // Email address custom validation
      let validEmail = (this.state.email !== "");
      let validEmailFormat = true;

      if(validEmail) {
          validEmailFormat = (this.state.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null);
      }

      this.setState({ 
          validName: (this.state.name !== ""),
          validEmail: validEmail,
          validEmailFormat: validEmailFormat,
          validPhone: (this.state.phone !== ""),
          validGender: (this.state.gender !== ""),
          validBirthDate: (this.state.birthDate !== null),
          validIdesocialhub: (this.state.idesocialhub !== ""),
          validConditions: (this.state.acceptConditions !== false)
      });

      return (
          (this.state.name !== "") &&
          (validEmail) &&
          (validEmailFormat) &&
          (this.state.phone !== "") &&
          (this.state.gender !== "") &&
          (this.state.birthDate !== null) &&
          (this.state.idesocialhub !== "") &&
          (this.state.acceptConditions !== false)
      );
  }


  render() {
    return (

      <>

        {
            (this.props.selectedTraining !== null) ?
                <div className='vacancy-full-container'>
                    <div className='container grid py-8 px-4 lg:px-0'>

                    <div className='col-2'>
                        <img className='w-8' src={this.props.selectedTraining.partnerId === 9 ? edit : wildcodeschool}  alt='edit'/>
                      </div>
                      <div className='col-10'>
                        <p className='text-3xl font-medium'>{this.props.selectedTraining.partner}</p>
                        <p className='text-6xl font-bold'>{this.props.selectedTraining.title}</p>
                      </div>
                      <div className='col-12 lg:col-4'>
                        <p className='text-3xl text-300'>{this.props.t("candidature-signup.labels.duration")}</p>
                        <p className='text-3xl'>{this.props.selectedTraining.duration}</p>
                      </div>
                      <div className='col-12 lg:col-4 '>
                        <p className='text-3xl text-300'>{this.props.t("candidature-signup.labels.begin-date")}</p>
                        <p className='text-3xl'>{this.props.selectedTraining.beginDate !== null ? getPrettyDate(this.props.selectedTraining.beginDate) : this.props.selectedTraining.beginDatePlaceholder}</p>
                      </div>
                      <div className='col-12 lg:col-4'>
                        <p className='text-3xl text-300'>{this.props.t("candidature-signup.labels.end-date")}</p>
                        <p className='text-3xl'>{this.props.selectedTraining.endDate !== null ? getPrettyDate(this.props.selectedTraining.endDate) : this.props.selectedTraining.endDatePlaceholder}</p>
                      </div>
                    </div>
                  </div>
            : null
        }
        {/* ----- Form Section ---- */}
        <div className='vancancy-match-full-container border-bottom-1 surface-border'>
          <div className='container grid vancancy-form py-8'>
            <div className='col-12'>
              <div className='col-12 lg:col-6 px-8'>
                <p className='text'>{this.props.t("candidature-signup.labels.form-title")}</p>
              </div>
              <div className='col-6'>
              </div>
            </div>
            <div className='col-12 lg:col-6 px-8'>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("candidature-signup.form.name")}</label>
                <input
                  id='name'
                  type='text'
                  value={this.state.name}
                  placeholder={this.props.t("candidature-signup.form.name-placeholder")}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("name", evt.target.value)} 
                />
                { this.state.validName ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("candidature-signup.form.email")}</label>
                <input
                  id='name'
                  type='email'
                  placeholder={this.props.t("candidature-signup.form.email-placeholder")}
                  value={this.state.email}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("email", evt.target.value)} 
                />
                { this.state.validEmail ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
                { this.state.validEmailFormat ? null : <div className="invalid-message">{this.props.t("general.wrong-email-format")}</div> }
              </div>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("candidature-signup.form.gender")}</label>
                <select
                  id='name'
                  type='text'
                  value={this.state.gender}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("gender", evt.target.value)} 
                >
                  <option disabled={true} value=''>
                  {this.props.t("candidature-signup.form.choose-option")}
                  </option>
                  <option value={"Feminino"}>Feminino</option>
                  <option value={"Masculino"}>Masculino</option>
                  <option value={"Outro"}>Outro</option>
                </select>
                { this.state.validGender ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
              <div className='field'>
                <label className='font-medium'>{this.props.t("candidature-signup.form.ethnic")}</label>
                <select
                  id='name'
                  type='text'
                  value={this.state.ethnic}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("ethnic", evt.target.value)} 
                >
                  <option disabled={true} value=''>
                  {this.props.t("candidature-signup.form.choose-option")}
                  </option>
                  <option value={"Caboclos"}>Caboclos</option>
                  <option value={"Cafuzos"}>Cafuzos</option>
                  <option value={"Caucásia"}>Caucásia</option>
                  <option value={"Indígenas"}>Indígenas</option>
                  <option value={"Mongolóide"}>Mongolóide</option>
                  <option value={"Mulatos"}>Mulatos</option>
                  <option value={"Negros"}>Negros</option>
                  <option value={"Pardos"}>Pardos</option>
                  <option value={"Outro"}>Outro</option>
                </select>
              </div>

              <div className='field'>
                <label className='font-medium'>{this.props.t("candidature-signup.form.school-level")}</label>
                <select
                  id='name'
                  type='text'
                  value={this.state.schoolLevel}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("schoolLevel", evt.target.value)} 
                >
                  <option disabled={true} value=''>
                  {this.props.t("candidature-signup.form.choose-option")}
                  </option>
                  <option value={"Menos de 4 anos de escolaridade"}>Menos de 4 anos de escolaridade</option>
                  <option value={"4 anos de escolaridade (1º ciclo do ensino básico)"}>4 anos de escolaridade (1º ciclo do ensino básico)</option>
                  <option value={"6 anos de escolaridade (2º ciclo do ensino básico)"}>6 anos de escolaridade (2º ciclo do ensino básico)</option>
                  <option value={"9º ano (3º ciclo do ensino básico)"}>9º ano (3º ciclo do ensino básico)</option>
                  <option value={"10º ano"}>10º ano</option>
                  <option value={"11º ano"}>11º ano</option>
                  <option value={"12º ano"}>12º ano</option>
                  <option value={"Curso tecnológico / Profissional"}>Curso tecnológico / Profissional</option>
                  <option value={"Bacharelato"}>Bacharelato</option>
                  <option value={"Licenciatura"}>Licenciatura</option>
                  <option value={"Mestrado"}>Mestrado</option>
                  <option value={"Doutorado"}>Doutorado</option>
                  <option value={"Pós-doutorado"}>Pós-doutorado</option>
                  <option value={"Pós-graduação"}>Pós-graduação</option>
                  <option value={"Curso de especialização tecnológica"}>Curso de especialização tecnológica</option>
                  <option value={"Habilitação ignorada"}>Habilitação ignorada</option>
                </select>
              </div>
              <div className='field'>
                <label className='font-medium'>{this.props.t("candidature-signup.form.job-situation")}</label>
                <select
                  id='name'
                  type='text'
                  value={this.state.jobSituation}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("jobSituation", evt.target.value)} 
                >
                  <option disabled={true} value=''>
                  {this.props.t("candidature-signup.form.choose-option")}
                  </option>
                  <option value={"Empregado"}>Empregado</option>
                  <option value={"Estudante"}>Estudante</option>
                  <option value={"Desempregado"}>Desempregado</option>
                  <option value={"Subsídio Desemprego ou Social"}>Subsídio Desemprego ou Social</option>
                  <option value={"Outro"}>Outro</option>
                </select>
              </div>

              <div className='field mt-3'>
                <p className='font-medium'>{this.props.t("candidature-signup.form.english-level")}</p>
                <select
                  id='name'
                  type='text'
                  value={this.state.englishLevel}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("englishLevel", evt.target.value)} 
                >
                  <option disabled={true} value=''>
                  {this.props.t("candidature-signup.form.choose-option")}
                  </option>
                  <option value={"Nenhum"}>Nenhum</option>
                  <option value={"Básico (A1-A2)"}>Básico (A1-A2)</option>
                  <option value={"Intermediário (B1-B2)"}>Intermediário (B1-B2)</option>
                  <option value={"Avançado (C1-C2)"}>Avançado (C1-C2)</option>
                </select>   
              </div>


              <div className='field-checkbox pt-3'>
                <input type='checkbox' id='privacy-policy' defaultChecked={this.state.acceptConditions} onChange={(evt) => this.updateCandidatureData("acceptConditions", evt.target.checked)}/>
                <label>
                  {this.props.t("candidature-signup.form.accept-cookies")}
                  <a style={{ color: 'inherit', textDecoration: 'underline' }} href={"/privacy-policy"} target="_self">{this.props.t("candidature-signup.form.privacy-policy")}</a>
                </label>
                { this.state.validConditions ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
            </div>
            <div className='vancancy-form col-12 lg:col-6 relative z-0 px-8'>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("candidature-signup.form.birth-date")}</label>
                <input
                  id='name'
                  type='date'
                  value={this.state.birthDate}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("birthDate", evt.target.value)} 
                />
                { this.state.validBirthDate ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>

              <div className='field'>
                <label className='font-medium required'>{this.props.t("candidature-signup.form.contact-phone")}</label>
                <input
                  id='name'
                  type='text'
                  placeholder={this.props.t("candidature-signup.form.contact-phone-placeholder")}
                  value={this.state.phone}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("phone", evt.target.value)} 
                />
                { this.state.validPhone ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
              <div className='field'>
                <label className='font-medium'>{this.props.t("candidature-signup.form.birth-place")}</label>
                <select
                  id='name'
                  type='text'
                  value={this.state.birthPlace}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("birthPlace", evt.target.value)} 
                >
                  <option disabled={true} value=''>
                  {this.props.t("candidature-signup.form.choose-option")}
                  </option>
                  <option value="Portugal">Portugal</option>
                  <option value="Afeganistão">Afeganistão</option>
                  <option value="África do Sul">África do Sul</option>
                  <option value="Albânia">Albânia</option>
                  <option value="Alemanha">Alemanha</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antigua e Deps">Antigua e Deps</option>
                  <option value="Arábia Saudita">Arábia Saudita</option>
                  <option value="Argélia">Argélia</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Arménia">Arménia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Austrália">Austrália</option>
                  <option value="Áustria">Áustria</option>
                  <option value="Azerbaijão">Azerbaijão</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Bélgica">Bélgica</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermudas">Bermudas</option>
                  <option value="Bielorússia">Bielorússia</option>
                  <option value="Bolívia">Bolívia</option>
                  <option value="Bósnia Herzegovina">Bósnia Herzegovina</option>
                  <option value="Botswana">Botswana</option>
                  <option value="Brasil">Brasil</option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgária">Bulgária</option>
                  <option value="Burkina">Burkina</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Butão">Butão</option>
                  <option value="Cabo Verde">Cabo Verde</option>
                  <option value="Camarões">Camarões</option>
                  <option value="Camboja">Camboja</option>
                  <option value="Canadá">Canadá</option>
                  <option value="Catar">Catar</option>
                  <option value="Cazaquistão">Cazaquistão</option>
                  <option value="Chade">Chade</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Chipre">Chipre</option>
                  <option value="Cidade do Vaticano">Cidade do Vaticano</option>
                  <option value="Colômbia">Colômbia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Coreia do Norte">Coreia do Norte</option>
                  <option value="Coreia do Sul">Coreia do Sul</option>
                  <option value="Costa do Marfim">Costa do Marfim</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Croácia">Croácia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Curaçao">Curaçao</option>
                  <option value="Dinamarca">Dinamarca</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Egito">Egito</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Emirados Árabes Unidos">Emirados Árabes Unidos</option>
                  <option value="Equador">Equador</option>
                  <option value="Eritreia">Eritreia</option>
                  <option value="Eslováquia">Eslováquia</option>
                  <option value="Eslovénia">Eslovénia</option>
                  <option value="Espanha">Espanha</option>
                  <option value="Estados Unidos">Estados Unidos</option>
                  <option value="Estónia">Estónia</option>
                  <option value="Etiópia">Etiópia</option>
                  <option value="Federação Russa">Federação Russa</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Filipinas">Filipinas</option>
                  <option value="Finlândia">Finlândia</option>
                  <option value="França">França</option>
                  <option value="Gabão">Gabão</option>
                  <option value="Gâmbia">Gâmbia</option>
                  <option value="Gana">Gana</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Grécia">Grécia</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Gronelândia">Gronelândia</option>
                  <option value="Guadalupe">Guadalupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guiana">Guiana</option>
                  <option value="Guiana Francesa">Guiana Francesa</option>
                  <option value="Guiné">Guiné</option>
                  <option value="Guiné Equatorial">Guiné Equatorial</option>
                  <option value="Guiné-bissau">Guiné-bissau</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Holanda">Holanda</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungria">Hungria</option>
                  <option value="Iémen">Iémen</option>
                  <option value="Ilhas Caimão">Ilhas Caimão</option>
                  <option value="Ilhas Cook">Ilhas Cook</option>
                  <option value="Ilhas Faroé">Ilhas Faroé</option>
                  <option value="Ilhas Marshall">Ilhas Marshall</option>
                  <option value="Ilhas Salomão">Ilhas Salomão</option>
                  <option value="Índia">Índia</option>
                  <option value="Indonésia">Indonésia</option>
                  <option value="Irão">Irão</option>
                  <option value="Iraque">Iraque</option>
                  <option value="Irlanda">Irlanda</option>
                  <option value="Islândia">Islândia</option>
                  <option value="Israel">Israel</option>
                  <option value="Itália">Itália</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japão">Japão</option>
                  <option value="Jordânia">Jordânia</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Laos">Laos</option>
                  <option value="Lesoto">Lesoto</option>
                  <option value="Letónia">Letónia</option>
                  <option value="Líbano">Líbano</option>
                  <option value="Libéria">Libéria</option>
                  <option value="Líbia">Líbia</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lituânia">Lituânia</option>
                  <option value="Luxemburgo">Luxemburgo</option>
                  <option value="Macau">Macau</option>
                  <option value="Macedonia">Macedonia</option>
                  <option value="Madagáscar">Madagáscar</option>
                  <option value="Malásia">Malásia</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Maldivas">Maldivas</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marrocos">Marrocos</option>
                  <option value="Martinica">Martinica</option>
                  <option value="Maurício">Maurício</option>
                  <option value="Mauritânia">Mauritânia</option>
                  <option value="México">México</option>
                  <option value="Mianmar">Mianmar</option>
                  <option value="Micronésia">Micronésia</option>
                  <option value="Moçambique">Moçambique</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Mongólia">Mongólia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Nicarágua">Nicarágua</option>
                  <option value="Níger">Níger</option>
                  <option value="Nigéria">Nigéria</option>
                  <option value="Noruega">Noruega</option>
                  <option value="Nova Zelândia">Nova Zelândia</option>
                  <option value="Omã">Omã</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestina">Palestina</option>
                  <option value="Panamá">Panamá</option>
                  <option value="Papua Nova Guiné">Papua Nova Guiné</option>
                  <option value="Paquistão">Paquistão</option>
                  <option value="Paraguai">Paraguai</option>
                  <option value="Peru">Peru</option>
                  <option value="Polinésia Francesa">Polinésia Francesa</option>
                  <option value="Polônia">Polônia</option>
                  <option value="Porto Rico">Porto Rico</option>
                  <option value="Quénia">Quénia</option>
                  <option value="Quirguistão">Quirguistão</option>
                  <option value="Reino Unido">Reino Unido</option>
                  <option value="Rep. Centro-Africano">Rep. Centro-Africano</option>
                  <option value="Rep. Democrática Congo">Rep. Democrática Congo</option>
                  <option value="República Checa">República Checa</option>
                  <option value="República Dominicana">República Dominicana</option>
                  <option value="Reunião">Reunião</option>
                  <option value="Romênia">Romênia</option>
                  <option value="Ruanda">Ruanda</option>
                  <option value="Samoa">Samoa</option>
                  <option value="Samoa Americana">Samoa Americana</option>
                  <option value="Santa Lúcia">Santa Lúcia</option>
                  <option value="São Marino">São Marino</option>
                  <option value="São Tomé e Príncipe">São Tomé e Príncipe</option>
                  <option value="São Vicente e Granadinas">São Vicente e Granadinas</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serra Leoa">Serra Leoa</option>
                  <option value="Sérvia">Sérvia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Singapura">Singapura</option>
                  <option value="Síria">Síria</option>
                  <option value="Somália">Somália</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Suazilândia">Suazilândia</option>
                  <option value="Sudão">Sudão</option>
                  <option value="Suécia">Suécia</option>
                  <option value="Suíça">Suíça</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Tahiti">Tahiti</option>
                  <option value="Tailândia">Tailândia</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajiquistão">Tajiquistão</option>
                  <option value="Tanzânia">Tanzânia</option>
                  <option value="Timor Leste">Timor Leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Trinidad e Tobago">Trinidad e Tobago</option>
                  <option value="Tunísia">Tunísia</option>
                  <option value="Turcomenistão">Turcomenistão</option>
                  <option value="Turquia">Turquia</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Ucrânia">Ucrânia</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Uruguai">Uruguai</option>
                  <option value="Uzbequistão">Uzbequistão</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietname">Vietname</option>
                  <option value="Zaire">Zaire</option>
                  <option value="Zâmbia">Zâmbia</option>
                  <option value="Zimbábue">Zimbábue</option>
                </select>
              </div>
              <div className='field'>
                <label className='font-medium'>{this.props.t("candidature-signup.form.residence-time")}</label>
                <input
                  id='name'
                  type='text'
                  value={this.state.portugueseResidenceTime}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("portugueseResidenceTime", evt.target.value)} 
                />
              </div>
              <div className='field'>
                <label className='font-medium'>{this.props.t("candidature-signup.form.profession")}</label>
                <input
                  id='name'
                  type='text'
                  value={this.state.profession}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("profession", evt.target.value)} 
                />
              </div>
              <div className='field mt-3'>
                <p className='font-medium required'>{this.props.t("candidature-signup.form.it-experience")}</p>
                <select
                  id='name'
                  type='text'
                  value={this.state.itExperience}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("itExperience", evt.target.value)} 
                >
                  <option disabled={true} value=''>
                  {this.props.t("candidature-signup.form.choose-option")}
                  </option>
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </select>
              </div>
              <div className='field mt-3'>
                <p className='font-medium required'>{this.props.t("candidature-signup.form.idesocialhub")}</p>
                <select
                  id='name'
                  type='text'
                  value={this.state.idesocialhub}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updateCandidatureData("idesocialhub", evt.target.value)} 
                >
                  <option disabled={true} value=''>
                  {this.props.t("candidature-signup.form.choose-option")}
                  </option>
                  <option value={"Linkedin"}>Linkedin</option>
                  <option value={"Instagram"}>Instagram</option>
                  <option value={"Outra"}>Outra</option>
                </select>         
                { this.state.validIdesocialhub ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }       
              </div>
            </div>
            <div className='col-12 flex justify-content-center'>
              <input type='submit' className='b-form c-pointer submit-button pt-3' value={this.props.t("candidature-signup.form.submit-form")} onClick={(evt) => this.submitCandidature()} />
            </div>
          </div>
          <Toast position='bottom-left' ref={this._toast} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedTraining: state.training.selectedTraining
  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(SignUp));