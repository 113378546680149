export const SECURITY_HEADER = "x-authorization-key";

export const YES_OPTION = { name: 'Sim', code: 'Sim' };
export const NO_OPTION = { name: 'Não', code: 'Não' };

export const DROPDOWN_YES_NO = [
    YES_OPTION,
    NO_OPTION
];

export const PARTNER_TYPE_PARTNER = "1";
export const PARTNER_TYPE_EMPLOYER = "2";