import httpRequest from '../../common/lib/http-request';

// *************************
// CANDIDATE SERVICES
// *************************

function submitCandidature(candidatureData) {
    return httpRequest({
        url: '/Candidate/candidature',
        method: 'POST',
        data: candidatureData
    });
}

const CandidateService = { 
    submitCandidature
}

export default CandidateService;