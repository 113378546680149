import { GET_ACTIVE_TRAININGS_SUCCESS, SELECT_TRAINING } from "../action-types/training";
import TrainingService from "../services/training";

// *******************************
//  GET ACTIVE TRAININGS
// *******************************

export const getActiveTrainings = () => {
    return dispatch => {
        TrainingService.getActiveTrainings()
        .then((response) => {
            dispatch(getActiveTrainingsSuccess(response));
        })
        .catch(error => {
            //console.error(error);
        });
    };
}

export const getActiveTrainingsSuccess = (trainings) => {
    return  {
        type: GET_ACTIVE_TRAININGS_SUCCESS,
        trainings: trainings
    };
}

// *******************************
//  SELECT TRAINING FOR SIGNUP
// *******************************
export const selectTraining = (training) => {
    return  {
        type: SELECT_TRAINING,
        selectedTraining: training
    };
}
