// Dependencies
import React, { Component } from 'react';
import AboutUsCard from '../../components/about-us-card/AboutUsCard';

// Styling
import './about-us.scss';

// Multi lang
import { withTranslation } from 'react-multi-lang';

// Redux
import { connect } from 'react-redux';

// Images
import mission from '../../common/assets/images/mission.jpg';
import sustainability from '../../common/assets/images/sustainability.png';
import people from '../../common/assets/images/people.png';
import seedling from '../../common/assets/images/Seedling.png';
import doves from '../../common/assets/images/doves.png';
import handshake from '../../common/assets/images/Handshake.png';
import planetearth from '../../common/assets/images/Planet Earth.png';

class AboutUsPage extends Component {

  componentDidMount() {

  }

  render() {
    return (
        <>
            {/* ----- About Us Section ---- */}

            <div className="aboutus-content-fluid">
                <div className="grid">
                    <div className="w-12 aboutusimg">
                        <div className="h-full bg-black-alpha-60 flex flex-column justify-content-center align-items-center">
                            <div
                                
                                className="sub-title lg:text-8xl text-4xl font-bold"
                            >
                                {this.props.t("about-us.title")}
                            </div>
                            <div className="text w-7 lg:text-6xl text-center text-2xl text-white font-medium">
                                {this.props.t("about-us.labels.subtitle")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ----- Platform Integration Section---- */}

            <div className="plat-integration-content-fluid">
                <div className="container grid py-8">
                    <div className="col-12 lg:col-4">
                        <p className="sub-title text-left px-6" style={{fontSize:"50px"}}>
                            {this.props.t("about-us.labels.platform-1")} <br />{" "}
                            {this.props.t("about-us.labels.platform-2")}
                            <br /> {this.props.t("about-us.labels.platform-3")}
                        </p>
                    </div>
                    <div className="col-12 lg:col-4">
                        <p className="text px-6" style={{fontSize:"24px"}}>
                            {this.props.t("about-us.labels.mission")}
                        </p>
                    </div>
                    <div className="col-12 lg:col-4">
                        <p className="text px-6" style={{fontSize:"24px"}}>
                            {this.props.t("about-us.labels.mission-3")}
                        </p>
                    </div>
                    <div className="col-12 lg:col-4">
                        <p className="text px-6">
                        
                        </p>
                    </div>
                    <div className="col-12 lg:col-4">
                        <p className="history text px-6" style={{fontSize:"24px"}}>
                            {this.props.t("about-us.labels.mission-2")}
                        </p>
                    </div>
                </div>
            </div>

            {/* ----- We Do Section---- */}

           

            {/* ----- Mission Section---- */}

            <div className="mission-content-fluid">
                <div className="container grid">
                    <div className="col-12 lg:col-5">
                        <div className="grid">
                            <div className="col-12 p-5">
                                <p className="about-us-title"style={{fontSize:"36px"}}>
                                    {this.props.t(
                                        "about-us.labels.mission-title"
                                    )} 
                                </p>
                                <p className="about-us-text" style={{fontSize:"24px"}}>
                                    {this.props.t(
                                        "about-us.labels.mission-description"
                                    )}
                                </p>
                            </div>
                            <div className="col-12 p-5">
                                <p className="about-us-title"style={{fontSize:"36px"}}>
                                    {this.props.t("about-us.labels.values")}
                                </p>
                                <p className="about-us-text"style={{fontSize:"24px"}}>
                                    {this.props.t(
                                        "about-us.labels.values-description"
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-7 flex justify-content-center">
                        <img
                            className="w-10 align-self-center"
                            src={mission}
                            alt="Mission"
                            style={{ height:"500px"}}
                        />
                    </div>
                </div>
            </div>

            {/* ----- Sustainability Section---- */}

        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(AboutUsPage));