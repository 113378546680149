export const getPrettyDate = function (date) {
    if(date === null) return null;
    
    let year = date.substring(0, 4);
    let month = date.substring(5, 7);
    let day = date.substring(8, 10);

    return day + "-" + month + "-" + year;
}

export const getPrettyDatetime = function (date) {
    if(date === null) return null;
    
    let year = date.substring(0, 4);
    let month = date.substring(5, 7);
    let day = date.substring(8, 10);
    let hour = date.substring(11, 13);
    let minute = date.substring(14, 16);
    let second = date.substring(17, 19);

    return day + "-" + month + "-" + year + " " + hour + ":" + minute + ":" + second;
}