// Dependencies
import React, { Component } from 'react';

// Styling
import './events.scss';

// Multi lang
import { withTranslation } from 'react-multi-lang';

// Redux
import { connect } from 'react-redux';

// Images
import websummitrio from '../../common/assets/images/websummitrio.png';
import euroleads from '../../common/assets/images/brasileuroleads.jpg';
import mulheresnaliderança from '../../common/assets/images/mulheresnaliderança.png';
import ideinsights from "../../common/assets/images/savethedate.png"

class EventsPages extends Component {

  componentDidMount() {
  }

  getPicture(eventId) {
    switch (eventId) {
      case 1:
        return euroleads;
      case 2:
        return websummitrio;
      case 3:
        return mulheresnaliderança;
      case 4:
        return ideinsights;
    }
  }

  render() {
    return (
        <>
            <div>
                <div className="event-title-container-fluid">
                    <div className="event-title-container container grid flex flex-column">
                        <p className="sub-title text-2xl font-normal">
                            {this.props.t("events.labels.future")}
                        </p>
                        <p className="sub-title text-5xl">
                            {this.props.t("events.labels.events")}
                        </p>
                    </div>
                </div>
                <div className="events-container-fluid">
                    <div className="container grid event-cards py-6 px-4  justify-content-center">
                        {this.props.events.map((event) => (
                            <div
                                key={event.id}
                                className="event-card card flex flex-column p-0 flex-wrap w-30rem sm:m-3 h-full"
                            >
                                <div
                                    className="w-full border-round-top-xl bg-cover bg-no-repeat bg-center"
                                    alt="WebSummit"
                                    style={{
                                        backgroundImage: `url(${this.getPicture(
                                            event.id
                                        )})`,
                                        height: 290,
                                    }}
                                ></div>
                                <div className="p-5">
                                    <p className="text-base text-400 p-0">
                                        {event.place} - {event.date}
                                    </p>
                                    <div className="h-16rem ">
                                        <p className="title font-bold pb-1 m-0">
                                            {event.title}
                                        </p>
                                        <p className="text p-0 text-overflow-ellipsis overflow-hidden surface-overlay h-12rem">
                                            {event.description}
                                        </p>
                                    </div>

                                    <button
                                        className="b-offers c-pointer align-self-end border-round-3xl w-12 m-auto py-3"
                                        onClick={(evt) => {
                                            window.open(event.url, "_blank");
                                        }}
                                        style={{background:"#E65531"}}
                                    >
                                        Ver mais
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.event.events
  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(EventsPages));