import { combineReducers } from 'redux';
import eventReducer from './event';
import offerReducer from './offer';
import trainingReducer from './training';

const rootReducer = combineReducers({
    event: eventReducer,
    offer: offerReducer,
    training: trainingReducer
});

export default rootReducer;