import { GET_ACTIVE_TRAININGS_SUCCESS, SELECT_TRAINING } from "../action-types/training";

const initialState = {
    trainings: [],
    selectedTraining: null,
};

const trainingReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_ACTIVE_TRAININGS_SUCCESS:
            return {
                ...state,
                trainings: action.trainings.trainings
            };
        case SELECT_TRAINING:
            return {
                ...state,
                selectedTraining: action.selectedTraining
            };
        default:
            return state;
    }
}

export default trainingReducer;