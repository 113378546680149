// Dependencies
import React, { Component } from 'react';
import { Toast } from 'primereact/toast';

// Styling
import './corporate.scss';

// Multi lang
import { withTranslation } from 'react-multi-lang';

// Redux
import { connect } from 'react-redux';
import PartnerService from '../../redux/services/partner';

// Images
import partnersandhiring from '../../common/assets/images/partnersandhiring.png';

// Utils
import { PARTNER_TYPE_EMPLOYER, PARTNER_TYPE_PARTNER } from '../../common/constants';

class CorporatePage extends Component {

  constructor(props) {
    super(props);
    this._toast = React.createRef();
    this.state = {
        name: "",
        email: "",
        mobilePhone: "",
        subject: "",
        message: "",
        company: "",
        partnerType: PARTNER_TYPE_PARTNER,
        acceptConditions: false,
        // Validation variables
        validName: true,
        validEmail: true,
        validEmailFormat: true,
        validPhone: true,
        validSubject: true,
        validMessage: true,
        validCompany: true,
        validConditions: true
    };
  }
  
  componentDidMount() {

  }

  submitPartner() {
      if(this.validateForm()) {
          let partner = {
              responsible: this.state.name,
              email: this.state.email,
              mobilePhone: this.state.mobilePhone,
              companyName: this.state.company,
              partnerType: Number(this.state.partnerType),
              message: {
                subject: this.state.subject,
                message: this.state.message
              }
          };

        PartnerService.submitPartner(partner)
        .then((response) => {
            this._toast.current.show({ severity: 'success', detail: this.props.t("corporate.messages.success"), life: 3000 });
        })
        .catch(error => {
            this._toast.current.show({ severity: 'error', detail: this.props.t("corporate.messages.error"), life: 3000 });
        });
      }
  }

    validateForm() {
        // Email address custom validation
        let validEmail = (this.state.email !== "");
        let validEmailFormat = true;

        if(validEmail) {
            validEmailFormat = (this.state.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null);
        }

        this.setState({ 
            validName: (this.state.name !== ""),
            validEmail: validEmail,
            validEmailFormat: validEmailFormat,
            validPhone: (this.state.mobilePhone !== ""),
            validCompany: (this.state.company !== ""),
            validSubject: (this.state.subject !== ""),
            validMessage: (this.state.message !== ""),
            validConditions: (this.state.acceptConditions !== false)
        });

        return (
            (this.state.name !== "") &&
            (validEmail) &&
            (validEmailFormat) &&
            (this.state.mobilePhone !== "") &&
            (this.state.company !== "") &&
            (this.state.subject !== "") &&
            (this.state.message !== "") &&
            (this.state.acceptConditions !== false)
        );
    }

  updatePartnerData(field, value) {
      this.setState({ [field]: value });
  }

  render() {
    return (
      <>
        {/* ----- Corporate Title Section ---- */}

        <div className='coporate-title-full-content'>
          <div className='container grid'>
            <div className='col-6 col-offset-3 text-center py-8'>
              <p className='text-white text-5xl font-bold line-height-3'>{this.props.t("corporate.labels.title")}</p>
            </div>
          </div>
        </div>

        {/* ----- Partners Section ---- */}

        

        {/* ----- How IDE Section ---- */}

        <div className='how-ide-full-container'>
          <div className='container grid flex flex-row py-6'>
            <div className='col-12 lg:col-4 lg:col-offset-1 text-center lg:text-start'>
              <p className='text-5xl text-white font-bold'>{this.props.t("corporate.labels.how-to-partner-title")}</p>
            </div>
            <div className='col-12 lg:col-6 lg:col-offset-1 flex align-items-center justify-content-center lg:justify-content-start'>
              <p className='text-3xl text-white'>{this.props.t("corporate.labels.how-to-partner-description")}</p>
            </div>
          </div>
        </div>

        {/* ----- Form Section ---- */}
        <div className='vancancy-match-full-container border-bottom-1 surface-border'>
          <div className='container grid vancancy-form py-8'>
            <div className='col-12'>
              
              <div className='col-12 lg:col-6 px-8'>
                <p className='text'>{this.props.t("corporate.labels.form-title")}</p>
              </div>
              <div className='col-6'>
              </div>
            </div>
            <div className='col-12 lg:col-6 px-8'>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("corporate.form.name")}</label>
                <input
                  id='name'
                  type='text'
                  value={this.state.name}
                  placeholder={this.props.t("corporate.form.name-placeholder")}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updatePartnerData("name", evt.target.value)} 
                />
                { this.state.validName ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("corporate.form.email")}</label>
                <input
                  id='name'
                  type='email'
                  placeholder={this.props.t("corporate.form.email-placeholder")}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updatePartnerData("email", evt.target.value)} 
                />
                { this.state.validEmail ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
                { this.state.validEmailFormat ? null : <div className="invalid-message">{this.props.t("general.wrong-email-format")}</div> }
              </div>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("corporate.form.contact-phone")}</label>
                <input
                  id='name'
                  type='text'
                  placeholder={this.props.t("corporate.form.contact-phone-placeholder")}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updatePartnerData("mobilePhone", evt.target.value)} 
                />
                { this.state.validPhone ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("corporate.form.company")}</label>
                <input
                  id='name'
                  type='text'
                  placeholder={this.props.t("corporate.form.company-placeholder")}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updatePartnerData("company", evt.target.value)} 
                />
                { this.state.validCompany ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
              <div className='field-checkbox pt-3'>
                <input type='checkbox' id='privacy-policy' defaultChecked={this.state.acceptConditions} onChange={(evt) => this.updatePartnerData("acceptConditions", evt.target.checked)} />
                <label>
                  {this.props.t("corporate.form.accept-cookies")}
                  <a style={{ color: 'inherit', textDecoration: 'underline' }} href={"/privacy-policy"} target="_self">{this.props.t("corporate.form.privacy-policy")}</a>
                </label>
                { this.state.validConditions ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
            </div>
            <div className='vancancy-form col-12 lg:col-6 relative z-0 px-8'>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("corporate.form.partner-type")}</label>
                <select
                  id='name'
                  type='text'
                  value={this.state.partnerType}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updatePartnerData("partnerType", evt.target.value)} 
                >
                  <option value={PARTNER_TYPE_PARTNER}>{this.props.t("corporate.form.partner")}</option>
                  <option value={PARTNER_TYPE_EMPLOYER}>{this.props.t("corporate.form.employer")}</option>
                </select>
              </div>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("corporate.form.subject")}</label>
                <input
                  id='name'
                  type='text'
                  placeholder={this.props.t("corporate.form.subject-placeholder")}
                  className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full'
                  onChange={(evt) => this.updatePartnerData("subject", evt.target.value)} 
                />
                { this.state.validSubject ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
              <div className='field'>
                <label className='font-medium required'>{this.props.t("corporate.form.message")}</label>
                <textarea id="message" name="messageTextArea" rows="4" placeholder={this.props.t("corporate.form.message-placeholder")} style={{resize:"none"}} className='form-field b-form text text-base text-color surface-overlay p-2 border-bottom-1 border-top-none border-x-none border-solid border-round outline-none w-full' 
                          onChange={(evt) => this.updatePartnerData("message", evt.target.value)} 
                />
                { this.state.validMessage ? null : <div className="invalid-message">{this.props.t("general.mandatory-field")}</div> }
              </div>
            </div>
            <div className='col-12 flex justify-content-center'>
              <input type='submit' className='b-form c-pointer submit-button pt-3' value={this.props.t("corporate.form.submit")} onClick={(evt) => this.submitPartner()} />
            </div>
          </div>
          <Toast position='bottom-left' ref={this._toast} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(CorporatePage));