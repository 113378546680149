import httpRequest from '../../common/lib/http-request';

// *************************
// PARTNER SERVICES
// *************************

function getAllActiveOffers() {
    return httpRequest({
        url: '/Offer/active',
        method: 'GET'
    });
}

function getFeaturedOffers() {
    return httpRequest({
        url: '/Offer/featured',
        method: 'GET'
    });
}

function getOffersByPartner(partnerId) {
    return httpRequest({
        url: '/Offer/partner/' + partnerId,
        method: 'GET'
    });
}

function searchOffer(term) {
    return httpRequest({
        url: '/Offer/search/' + term,
        method: 'GET'
    });
}

function getOfferDetail(offerRef) {
    return httpRequest({
        url: '/Offer/' + offerRef,
        method: 'GET'
    });
}

const OfferService = { 
    getAllActiveOffers,
    getFeaturedOffers,
    getOffersByPartner,
    searchOffer,
    getOfferDetail
}

export default OfferService;