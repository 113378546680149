import { GET_FUTURE_EVENTS } from "../action-types/event";

const initialState = {
    events: [
        { id: 1, title: "Brasil Euro Leads", description: "Evento propõe compreender diferentes momentos do país, seus desafios e suas oportunidades.", date: "Março 2023", place: "Portugal", url: "https://www.gobrasa.org/" },
        { id: 2, title: "Web Summit Rio", description: "Maior evento de negócios, tecnologia e inovação do mundo, pela primeira vez no Brasil.", date: "Maio 2023", place: "Rio de Janeiro", url: "https://rio.websummit.com" },
        { id: 3, title: "Mulheres na Liderança", description: "O evento integra a agenda de atividades da 12ª Semana do Empreendedorismo de Lisboa", date: "Maio 2023", place: "Lisboa", url: "https://www.eventbrite.pt/e/bilhetes-encontro-mulheres-na-lideranca-e-no-empreendedorismo-634159105957" },
        { id: 4, title: "IDE Insights & Boas Práticas nas Empresas", description:"A 1.ª Edição IDE Insights & Boas Práticas nas Empresas será dedicado a compartilhar role models de Inclusão, Diversidade e Equidade em empresas nacionais e internacionais em Portugal.", date: "Setembro 2023", place: "Lisboa", url: "https://ury1.com/hJBoY"}
    ]
};

const eventReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_FUTURE_EVENTS:
            return {
                ...state,
                events: action.events
            };
        default:
            return state;
    }
}

export default eventReducer;