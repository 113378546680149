// Dependencies
import React, { Component } from 'react';
import logo from '../../common/assets/images/logo1.png'
import ig_logo from '../../common/assets/images/instagram_logo.png'
import linkedin_logo from '../../common/assets/images/linkedin_logo.png'
import email_logo from '../../common/assets/images/email_logo.png'
import whatsapp_logo from '../../common/assets/images/whatsapp_logo.png'
import iguality from '../../common/assets/images/iguality.png'
import erradication from '../../common/assets/images/erradication.png'
import desigualdade from '../../common/assets/images/desigualdade.png'
import economy from '../../common/assets/images/economy.png'

// Multi Lang
import { withTranslation } from 'react-multi-lang';

// Styling
import './footer.scss';

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <footer className='footer-fluid-container pt-7'>
        <div className='container grid px-3 lg:px-0'>
          <div className='col-6 lg:col-3 text-center '>
            <img className='w-9rem mt-2 h-9rem' src={logo} alt='logo' />
          </div>
          
          <div className='col-6 lg:col-3' style={{fontSize:"17px", color:"#fff"}}>
            <p><b><span>{this.props.t('footer.titles.sitemap')}</span></b></p>
            <ul className='footerMenu'>
              <li>
                <a style={{color: 'inherit'}} href={"/"} target="_self">
                  <span className='t-uppercase'>{this.props.t('footer.links.home')}</span></a>
              </li>
              <li>
                <a style={{color: 'inherit'}} href={"/about-us"} target="_self">
                  <span className='t-uppercase'>{this.props.t("about-us.title")}</span>
                </a>
              </li>
              <li>
                <span className='t-uppercase'>{this.props.t('footer.links.for-candidates')}</span>
              </li>
              <li>
                <span className='t-uppercase'>{this.props.t('footer.links.for-businesses')}</span>
              </li>
              <li>
                <a style={{color: 'inherit'}} href={"/events"} target="_self">
                  <span className='t-uppercase'>{this.props.t("events.title")}</span>
                </a>
              </li>
            </ul>
          </div>
          <div className='col-6 lg:col-3'style={{fontSize:"17px", color:"#fff"}}>
            <p><b><span>{this.props.t('footer.titles.useful-links')}</span></b></p>
            <ul className="footerMenu">
             <li><a style={{color: 'inherit'}} href="/recrutamento" target="_blank">{this.props.t("recrutamento.title")}</a></li>
              <li><a style={{color: 'inherit'}} href="/diversidade" target="_blank">{this.props.t("diversidade.title")}</a></li>
              <li><a style={{color: 'inherit'}} href="/censo" target="_blank">{this.props.t("censo.title")}</a></li>
            </ul>
          </div>
          <div className='col-6 lg:col-3'style={{fontSize:"17px", color:"#fff"}}>
          <p><b><span>{this.props.t('footer.titles.follow-our-social-medias')}</span></b></p>
            <ul className='footerMenuIcons'>
              <li><a style={{color: 'inherit'}} href="https://www.instagram.com/idesocialhub/" target="_blank"><img src={ig_logo} alt='logo' /></a></li>
              <li><a style={{color: 'inherit'}} href="https://www.linkedin.com/company/idesocialhub.org/" target="_blank"><img src={linkedin_logo} alt='logo' /></a></li>
            </ul>
            <p><b><span>{this.props.t('footer.titles.contact-us')}</span></b></p>
            <ul className='footerMenuIcons'>
              <li><a style={{color: 'inherit'}} href="mailto:contato@idesocialhub.pt" target="_blank"><img src={email_logo} alt='logo' /></a></li>
              <li><a style={{color: 'inherit'}} href="https://wa.me/351963005774/?text=Olá,%20tenho%20interesse%20nos%20serviços%20do%20IDE%20SocialHub!" target="_blank"><img src={whatsapp_logo} alt='logo' /></a></li>
            </ul>
            <img className='w-4' src={erradication} style={{height:"88px"}}></img>
            <img className='w-4' src={iguality} style={{height:"88px"}}></img><br></br>
            <img className='w-4' src={economy} style={{height:"88px"}}></img>
            <img className='w-4' src={desigualdade} style={{height:"88px"}}></img>
          </div>
        </div>
        <div className='col-12 text-center copyright'style={{fontSize:"17px", color:"#fff"}}>Copyright © 2022 - All Rights Reserved</div>
      </footer>
    );
  }
}

export default withTranslation(Footer);