import { GET_FEATURED_OFFERS_SUCCESS, GET_OFFER_DETAIL_SUCCESS, GET_OFFERS_BY_PARTNER_SUCCESS, GET_ACTIVE_OFFERS_SUCCESS, SEARCH_OFFERS_SUCCESS } from "../action-types/offer";
import OfferService from "../services/offer";

// *******************************
//  GET FEATURED OFFERS
// *******************************

export const getFeaturedOffers = () => {
    return dispatch => {
        OfferService.getFeaturedOffers()
        .then((response) => {
            dispatch(getFeaturedOffersSuccess(response));
        })
        .catch(error => {
            //console.error(error);
        });
    };
}

export const getFeaturedOffersSuccess = (offers) => {
    return  {
        type: GET_FEATURED_OFFERS_SUCCESS,
        offers: offers
    };
}

// *******************************
//  GET OFFER DETAIL
// *******************************

export const getOfferDetail = (offerRef, callback) => {
    return dispatch => {
        OfferService.getOfferDetail(offerRef)
        .then((response) => {
            dispatch(getOfferDetailSuccess(response));
            callback();
        })
        .catch(error => {
            //console.error(error);
        });
    };
}

export const getOfferDetailSuccess = (offer) => {
    return  {
        type: GET_OFFER_DETAIL_SUCCESS,
        offer: offer
    };
}

// *******************************
//  GET OFFERS BY PARTNER
// *******************************

export const getOffersByPartner = (partnerId, partner, callback) => {
    return dispatch => {
        OfferService.getOffersByPartner(partnerId)
        .then((response) => {
            dispatch(getOffersByPartnerSuccess(response, partner));
            callback();
        })
        .catch(error => {
            //console.error(error);
        });
    };
}

export const getOffersByPartnerSuccess = (offers, partner) => {
    return  {
        type: GET_OFFERS_BY_PARTNER_SUCCESS,
        offers: offers,
        searchTerm: partner
    };
}

// *******************************
//  GET ACTIVE OFFERS
// *******************************

export const getAllActiveOffers = () => {
    return dispatch => {
        OfferService.getAllActiveOffers()
        .then((response) => {
            dispatch(getAllActiveOffersSuccess(response));
        })
        .catch(error => {
            //console.error(error);
        });
    };
}

export const getAllActiveOffersSuccess = (offers) => {
    return  {
        type: GET_ACTIVE_OFFERS_SUCCESS,
        offers: offers
    };
}

// *******************************
//  SEARCH OFFERS
// *******************************

export const searchOffer = (term, callback) => {
    return dispatch => {
        OfferService.searchOffer(term)
        .then((response) => {
            dispatch(searchOfferSuccess(response, term));
            callback();
        })
        .catch(error => {
            //console.error(error);
        });
    };
}

export const searchOfferSuccess = (offers, term) => {
    return  {
        type: SEARCH_OFFERS_SUCCESS,
        offers: offers,
        searchTerm: term
    };
}