import httpRequest from '../../common/lib/http-request';

// *************************
// PARTNER SERVICES
// *************************

function submitPartner(partnerData) {
    return httpRequest({
        url: '/Partner',
        method: 'POST',
        data: partnerData
    });
}

const PartnerService = { 
    submitPartner
}

export default PartnerService;